import type { ErrorIdent } from '~/@types/errorIdents';
import { useSecurePost } from '~/composables/dataFetching/genericFetchers';
import type {
  Result,
  postBody,
} from '~/server/api/[site]/user/account/resetPw.post';
import { handleLoadingError } from '~/utils/handleLoadingError';

interface resetPasswordForm {
  password: {
    password: string;
    passwordConfirm: string;
  };
}

export async function useResetLegacyPasswordForm(hash: string) {
  const isLoading = ref(false);
  const showSuccess = ref(false);
  const errors = ref<ErrorIdent[]>([]);
  const site = useSiteIdent();

  async function onSubmit(data: resetPasswordForm) {
    isLoading.value = true;
    await resetLegacyPassword(data);
    isLoading.value = false;
  }

  async function resetLegacyPassword(formData: resetPasswordForm) {
    try {
      isLoading.value = true;
      const postData: postBody = {
        hash,
        newPassword: formData.password.password,
        newPasswordConfirm: formData.password.passwordConfirm,
      };

      await useSecurePost<Result>(
        `/api/${site}/user/account/resetPw`,
        postData,
      );

      showSuccess.value = true;
      isLoading.value = false;
    } catch (e: any) {
      errors.value = e.data.data.errors;
      document.querySelector('.js-error-container')?.scrollIntoView({
        behavior: 'smooth',
      });
      isLoading.value = false;
      handleLoadingError(e);
    }
  }

  return {
    isLoading,
    onSubmit,
    errors,
    showSuccess,
  };
}
